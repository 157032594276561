// This script looks for forms that have reCAPTCHA enabled and then sets them up
// to transmit the recaptcha response token when the form is submitted.
// The reCAPTCHA helper should then be used to validate the result ...

//console.log('recaptcha.js');

$(function() {

    initRecaptchaForms();

});

// Placeholder for the clicked form so we can submit it after the recaptcha callback
window.recaptchaForm = null;

function initRecaptchaForms() {

    //console.log('initRecaptchaForms');

    let siteKey = '';

    $('form[data-recaptcha-site-key]').each(function() {

        // Grab the google site key set as an attribute on the form
        siteKey = $(this).data('recaptcha-site-key');

        // Trap the form
        let $form = $(this);

        // Get submit input and button elements
        let $submitters = $(this).find('input[type=submit], button[type=submit]');

        $submitters.each(function() {

            // Add the g-recaptcha class to the submitter
            $(this).addClass('g-recaptcha');
            // Add the siteKey
            $(this).attr('data-sitekey', siteKey);
            // Add the callback
            $(this).attr('data-callback', 'recaptchaSubmit');
            // Add the action
            $(this).attr('data-action', 'submit');
            // Add click handler
            $(this).on('click', function() {
                recaptchaForm = $form;
            });

        });

    });

    // Need to load the Google recaptcha script after the data properties have been added to the form submit(s)
    if ($('form[data-recaptcha-site-key]').length) {
        // There are some reCAPTCHA forms - load the recaptcha script
        //console.log('Loading reCAPTCHA script');
        //$.getScript('https://www.google.com/recaptcha/api.js?render=' + siteKey);
        //$.getScript('https://www.google.com/recaptcha/api.js');
        $.getScript('https://www.google.com/recaptcha/enterprise.js?render=' + siteKey);
    }

}

window.recaptchaSubmit = function(token) {

    //console.log('recaptchaSubmit');
    //console.log(token);
    //console.log(recaptchaForm);

    // Submit the form trapped when the submit button was pressed
    recaptchaForm.submit();

};
